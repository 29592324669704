import { push }                                from 'connected-react-router';
import { dmpCommandSuccessContextualizedType } from 'dmpconnectjsapp-base/actions';
import commands                                from 'dmpconnectjsapp-base/actions/config/commands';
import { apiSections }                         from 'dmpconnectjsapp-base/constants';
import { getLoginReferer, }                    from 'dmpconnectjsapp-base/helpers/accessors';
import { documentStatuses }                    from 'dmpconnectjsapp-base/helpers/findDocuments';
import { put, select, take }                   from 'redux-saga/effects';
import { clearLoginReferer, getAction }        from '../actions';
import {
  findDocumentByUniqueId, getFindDocumentsLastSearchTimeStamp as getFindDocumentsLastSearchTimeStampAccessor,
  getFindDocumentsParams as getFindDocumentsParamsAccessor,
}                                              from '../helpers/findDocuments';
import { getHomePagePath }                     from '../helpers/nav';


export const redirectAfterLogin                  = function* () {
  const loginReferer = yield select(getLoginReferer);
  
  if (loginReferer) {
    yield put(clearLoginReferer());
    yield put(push(loginReferer));
  } else {
    const homePagePath = yield select(getHomePagePath);
    yield put(push(homePagePath));
  }
};// const getAllNewDocsFromCache = (state, ins) => {
export const getFindDocumentsParams              = state => getFindDocumentsParamsAccessor(state);
export const getFindDocumentsLastSearchTimeStamp = (state, ins) => getFindDocumentsLastSearchTimeStampAccessor(state, ins);
export const getDocumentInfoFromCache            = (state, ins, uniqueId) => {
  const {
          dmpconnectDocumentsCache: {
            [ins]: {
              documents: {
                [uniqueId]: document,
              } = {},
            } = {},
          },
        } = state;
  return document;
};
export const getAllDocsFromCache                 = (state, ins) => {
  const {
          dmpconnectDocumentsCache: {
            [ins]: {
              documents = {},
            } = {},
          },
        } = state;
  return Object.values(documents);
};
//   const {
//     dmpconnectDocumentsCache: {
//         [ins]: {
//           documents,
//         } = {},
//     },
//   } = state;
//   return findNewDocuments(documents);
// };
export const getNewDocFromCache             = (state, ins, uniqueId, exeptUuid) => {
  const {
          dmpconnectDocumentsCache: {
            [ins]: {
              documents,
            } = {},
          },
        } = state;
  
  const [doc] = findDocumentByUniqueId(documents, uniqueId, exeptUuid);
  return doc;
};
export const getLastApprovedNewDocFromCache = (state, ins, uniqueId, exeptUuid) => {
  const {
          dmpconnectDocumentsCache: {
            [ins]: {
              documents,
            } = {},
          },
        } = state;
  
  const docs = findDocumentByUniqueId(documents, uniqueId, exeptUuid);
  return docs.find(d => d.i_document_Status === documentStatuses.APPROVED);
};

export const hardResetApplication = function* () {
  yield put(getAction(
    commands.clearPersistantData,
    apiSections.CLEAR_PERSISTANT_DATA,
    undefined,
    { contextExtra: { refresh: false } },
  ));
  
  yield take(dmpCommandSuccessContextualizedType(apiSections.CLEAR_PERSISTANT_DATA));
  
  localStorage.clear();
  indexedDB.deleteDatabase('efficience_documents');
  indexedDB.deleteDatabase('efficience_mss_messages');
  indexedDB.deleteDatabase('efficience_mss_accounts');
  indexedDB.deleteDatabase('efficience_interop_codes');
  indexedDB.deleteDatabase('efficience_td04');
  indexedDB.deleteDatabase('efficience_commands');
  window.location.href = '/';
};
