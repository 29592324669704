import React from 'react';
import { API_TYPES, apiSections } from 'dmpconnectjsapp-base/constants';
import { hasError, isLoading, isReady } from 'dmpconnectjsapp-base/helpers/common';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import { getApiType, getInsiConfig } from 'dmpconnectjsapp-base/helpers/accessors';
import moment from 'moment';
import CheckAccessRights from '../AccessRights/CheckAccessRights';
import { checkAndGetINSiIdentity, getInsFromVitaleCard } from '../../dmpconnect/actions';
import {
  fixQualifIdentityOid,
  getCertifiedIdentityFromIns,
  getQualifiedInsINSi,
} from '../../dmpconnect/helpers/certifiedIdentity';
import TLSiConnector from '../TLSi/TLSiConnector';
import TD0IdentityProvider from './TD0IdentityProvider';
import SectionStatusWrapper from '../Common/SectionStatus/SectionStatusWrapper';
import Loading from '../Common/Loading/Loading';
import { getPatientInsiIdentityFromAccessibleDMPsCache } from '../../dmpconnect/helpers/accessibleDMPList';

const INSiIdentityProvider = ({
  dispatch,
  children,
  certifiedIns,
  identity,
  checkIdentitySection,
  vitaleIndex,
  insFromVitaleCard,
  insiConfig,
  runOnMount,
  checkOnMount,
  showLoading,
  dmpStatusCall,
  ins2Patient,
  insiOnly,
  subSection,
  vitaleCardStatus,
  tlsiConnectorSection,
  apiType,
  vitaleXml,
}) => {
  const [isRunning, setIsRunning] = React.useState(false);
  const [checkIsRunning, setCheckIsRunning] = React.useState(false);
  const [ins, setIns] = React.useState(certifiedIns);
  React.useEffect(() => {
    if ( // we already have an identity, so we check it
      (apiType === API_TYPES.REST || isReady(tlsiConnectorSection))
      && !checkIsRunning
      && runOnMount
      && checkOnMount
      && isReady(identity)
      && identity.i_insIdentityResult === 2
      // we got the identity before today
      && (identity.Identity && moment(identity.Identity.s_lastInsiCallDate, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'), 'day'))
      && !hasError(checkIdentitySection)
      && !isLoading(checkIdentitySection)
      && !isLoading(identity)
      && insiConfig.active === true
    ) {
      setCheckIsRunning(true);
      dispatch(checkAndGetINSiIdentity({
        identity,
        subSection: subSection || certifiedIns,
      }));
    } else if ( // we didn't receive a certified ins, go get one
      (apiType === API_TYPES.REST || isReady(tlsiConnectorSection))
      && !isRunning
      && runOnMount
      && !certifiedIns
      && !isReady(identity)
      && !isLoading(identity)
      && !hasError(identity)
      && insiConfig.active === true
      // && (apiType !== API_TYPES.REST || (isReady(vitaleXml) && vitaleXml.xmlPatients !== undefined))
    ) {
      setIsRunning(true);
      dispatch(getInsFromVitaleCard({
        ins2Patient,
        insFromVitaleCard,
        vitaleIndex,
        dmpStatusCall,
        subSection,
        vitaleCardStatus,
        vitaleXml,
      }));
    }
  }, [
    isRunning,
    certifiedIns,
    vitaleIndex,
    isReady(identity),
    isLoading(identity),
    hasError(identity),
    dmpStatusCall,
    ins2Patient,
    subSection,
    runOnMount,
    checkOnMount,
    insiConfig.active,
    identity,
    apiType,
    vitaleXml,
  ]);


  React.useEffect(() => {
    if (isReady(identity)) {
      setIsRunning(false);

      if (identity.i_insIdentityResult === 2) {
        setIns(getQualifiedInsINSi(identity, vitaleCardStatus));
      } else {
        setIns(null);
      }
    } else {
      setIns(null);
    }
  }, [isReady(identity), vitaleCardStatus]);

  React.useEffect(() => {
    if (hasError(identity)) {
      setIsRunning(false);
    }
  }, [hasError(identity)]);


  if (
    insiConfig.active !== true
  ) {
    if (insiOnly) return children;

    return (
      <TD0IdentityProvider
        vitaleIndex={vitaleIndex}
        nir={insFromVitaleCard}
        certifiedIns={certifiedIns}
        runOnMount={runOnMount}
      >
        {children}
      </TD0IdentityProvider>
    );
  }


  return (
    <TLSiConnector>
      <SectionStatusWrapper
        error={false}
        loading={showLoading && (isLoading(identity) || isLoading(checkIdentitySection))}
        loadingContent={<Loading centered={false} size="sm" message={null} />}
        showChildrenOnError
      >
        {
        React.cloneElement(children, {
          certifiedIns: ins || certifiedIns,
          certifiedIdentity: identity.Identity || {},
          certifiedInsError: hasError(identity),
          certifiedInsLoading: isLoading(identity),
          certifiedInsReady: isReady(identity),
          vitaleIndex: identity && identity.params ? identity.params.vitaleIndex : vitaleIndex,
          certifiedIdentityResult: identity,
        })
      }
      </SectionStatusWrapper>
    </TLSiConnector>
  );
};

function mapStateToProps(state, props) {
  const {
    insFromVitaleCard, vitaleIndex, certifiedIns, subSection,
  } = props;
  const {
    dmpconnect: {
      [apiSections.TLSI_CONNECTOR_SECTION]: tlsiConnectorSection = {},
      [apiSections.INS_FROM_VITALE_CARD]: insiIdentitiesSection = {},
      [apiSections.INSI_CHECK_IDENTITY]: {
        [subSection || certifiedIns]: checkIdentitySection = {},
      } = {},
      [apiSections.VITALE_DATA_SECTION]: { i_cardStatus } = {},
      [apiSections.VITALE_XML_CONTENT_SECTION]: vitaleXml,
    },
  } = state;
  const insiConfig = getInsiConfig(state);

  let identity;
  let indexVitale = vitaleIndex;
  let nir = insFromVitaleCard;
  if (subSection) {
    const cachedIdentity = getPatientInsiIdentityFromAccessibleDMPsCache(state, subSection);
    if (cachedIdentity) {
      identity = { ...cachedIdentity, Identity: fixQualifIdentityOid(cachedIdentity.Identity) };
    } else {
      identity = insiIdentitiesSection[subSection] || {};
    }

    indexVitale = (identity.params || {}).vitaleIndex || -1;
    nir = (identity.params || {}).insFromVitaleCard || '';
  } else if (certifiedIns) {
    identity = getCertifiedIdentityFromIns(state, certifiedIns);
  } else if (insFromVitaleCard && Number(vitaleIndex) >= 0) {
    identity = insiIdentitiesSection[`${insFromVitaleCard}_${vitaleIndex}`] || {};
  }

  return {
    apiType: getApiType(state),
    vitaleXml,
    tlsiConnectorSection,
    vitaleIndex: indexVitale,
    insFromVitaleCard: nir,
    identity,
    insiConfig,
    vitaleCardStatus: i_cardStatus,
    checkIdentitySection,
  };
}

INSiIdentityProvider.propTypes = {
  children: PropTypes.node.isRequired,
  apiType: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  certifiedIns: PropTypes.string,
  tlsiConnectorSection: PropTypes.object,
  identity: PropTypes.object,
  vitaleXml: PropTypes.object,
  checkIdentitySection: PropTypes.object,
  vitaleIndex: PropTypes.number,
  insFromVitaleCard: PropTypes.string,
  insiConfig: PropTypes.object.isRequired,
  runOnMount: PropTypes.bool.isRequired,
  showLoading: PropTypes.bool,
  dmpStatusCall: PropTypes.bool,
  ins2Patient: PropTypes.object,
  insiOnly: PropTypes.bool,
  subSection: PropTypes.string,
  vitaleCardStatus: PropTypes.number,
  checkOnMount: PropTypes.bool,
};

INSiIdentityProvider.defaultProps = {
  vitaleIndex: -1,
  insFromVitaleCard: null,
  certifiedIns: null,
  tlsiConnectorSection: {},
  identity: {},
  checkIdentitySection: {},
  vitaleXml: {},
  showLoading: false,
  dmpStatusCall: true,
  ins2Patient: undefined,
  insiOnly: false,
  subSection: null,
  vitaleCardStatus: null,
  checkOnMount: false,
};


const ConnectedINSiIdentity = CheckAccessRights(connect(mapStateToProps)(INSiIdentityProvider));

export default ConnectedINSiIdentity;
